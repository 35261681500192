import * as React from "react"
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ReferenceBlock from "../components/template-partials/reference-block"
import FooterCrown from "../components/template-partials/footer-crown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

// import { AnchorLink } from "gatsby-plugin-anchor-links"

//images
import germBg from "../images/Master_cells.svg"

import aboutSmDykBg from "../images/about-sm-dyk-bg.png"
import aboutSmDykLesions from "../images/about-sm-dyk-desktop.png"

const AboutSMPage = () => (
  <Layout route="about-sm">
    <Seo
      page="about-sm"
      title="About SM"
      description="Learn about what systemic mastocytosis is, prevalence of the disease, the largest subtype, and the
diagnostic delay."
    />

    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-12 large-12">
        <h1 className="color--purple text--watch-quinn">
          Systemic mastocytosis (SM): A{" "}
          <span className="powder">complex and potentially debilitating </span>
          mast cell disorder<sup>1-3</sup>
        </h1>
      </div>
    </div>

    <div id="overview" className="row align-middle lg_mt-2 mt-2 mobile-padding">
      <div className="blue_card columns medium-12 large-6">
        <div className="row small-collapse medium-uncollapse">
          <div className="columns ">
            <div
              className="purple-card text-left purple-card--left purple-card--bottom-right-img purple-card--bottom-right-img--germs"
              style={{ backgroundImage: `url(${germBg})` }}
            >
              <h2 className="text--semibold mb-1 lightPink">What is SM?</h2>
              <p className="mb-2">
                SM is a clonal mast cell neoplasm associated with the
                uncontrolled proliferation and activation of abnormal mast cells
                throughout the body.<sup>1-3</sup>
              </p>{" "}
              <p className="mb-0">
                <strong>
                  SM is primarily driven by the{" "}
                  <a href="/diagnosing-sm/" className="white--link">
                    KIT D816V mutation
                  </a>
                  , which is present in ~95% of cases.<sup>4-6</sup>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="columns small-12  medium-expand text-center">
        <div className="row align-center">
          <div className="columns small-12 large-8">
            <div className="hr"></div>
            <p className="h4-18 mb-0 color--powder ">
              <strong>Estimated indidence</strong>
            </p>
            <p className="h1--md mb-0 color--purple text--watch-quinn">
              ~1 in 10,000
            </p>
            <p className="mb-0 h4 color--purple">
              US adults<sup>4</sup>
            </p>

            <p className="footnote_sm color--purple">
              <em>Although the true incidence of SM is unknown</em>
            </p>
            <div className="hr"></div>
          </div>
        </div>
      </div>
    </div>
    <div className="row lg_mt-2 mt-2 align-center medium-align-left">
      <div className="columns small-12 large-12 centered_div">
        <p className="h2 bold mt-2 s-mt-0 purple">
          Indolent systemic mastocytosis (ISM) represents the largest subtype of
          SM<sup>6-8</sup>
        </p>

        <p className="bodyP purple">
          A diagnosis of SM is categorized into subtypes that fall along a
          clinical spectrum based on disease burden, including ISM and Advanced
          SM.<sup>1</sup>
        </p>
      </div>
    </div>

    <div className="row lg_mt-1 mt-1 align-center medium-align-left">
      <div className="columns small-12 large-5 centered_div">
        <div className="percent_col one_col ">
          <div className="col_inner ">
            <p className="font20 purple">
              Regardless of SM subtype, SM can have a
            </p>
            <p className="percent_header underline_header">
              <a
                href="/recognizing-sm/living-with-sm/"
                className="percent_header_link"
              >
                <span className="text--watch-quinn">
                  detrimental impact to patients
                </span>
              </a>
            </p>
            <p className="font20 purple">
              living with the disease<sup>1</sup>
            </p>
          </div>
        </div>
      </div>
    </div>
    <br className="show-for-large" />
    <div className="row lg_mt-1 mt-2 align-center medium-align-left">
      <div className="columns small-12 large-12 centered_div">
        <p className="h2 bold mt-2 s-mt-1 s-mb-2 purple">
          Challenges in recognizing SM can result in a{" "}
          <a
            href="/recognizing-sm/symptoms"
            className="powder underline linkXtraBold"
          >
            prolonged time to diagnosis
          </a>
        </p>

        <p className="bodyP purple">
          Because of its heterogeneous and nonspecific symptoms, SM is often{" "}
          <a
            href="/recognizing-sm/related-conditions/"
            className="powder underline bold"
          >
            mistaken for other disorders
          </a>
          .<sup>3</sup> Receiving an accurate diagnosis can take an average of{" "}
          <strong>~6 years from symptom onset</strong>.<sup>9</sup>
        </p>

        <p className="bodyP purple s-mb-0">
          The delay in diagnosis is notable given the detrimental long-term
          effects that SM can have on patients, such as decreased overall
          survival from organ damage in Advanced SM.<sup>2,10</sup>
        </p>
      </div>
    </div>

    <div className="row lg_mt-2 mt-2 align-center medium-align-left">
      <div className="columns  medium-expand">
        <div className="percent_col two_col">
          <div className="col_inner borderRight">
            <p className="percent_header text--watch-quinn aboutPercent-header">
              <span className="purple">~5%</span>&nbsp;of&nbsp;patients
            </p>
            <p className="purple h3">
              have their indolent SM progress to more advanced
              <br className="show-for-large" /> forms of SM over time
              <sup>11</sup>{" "}
            </p>
          </div>

          <div className="col_inner center_col">
            <p className="percent_header text--watch-quinn aboutPercent-header">
              <span className="purple">&le;3.5</span> years
            </p>
            <p className="purple h3">
              is the median overall survival for patients
              <br className="show-for-large" /> with Advanced SM<sup>10</sup>*
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row lg_mt-2 mt-2 align-center medium-align-left">
      <div className="columns  medium-expand">
        <ul className="hanging-ref color--purple footnote_ul">
          <li>
            <span className="ref">*</span>
            Overall survival was examined in a retrospective study that included
            342 consecutive adult patients with SM (included 183 adults with
            Advanced SM) seen at the Mayo Clinic between 1976 and 2007. In
            aggressive systemic mastocytosis (ASM) (n=41), overall survival was
            41 months; 24 months in systemic mastocytosis with associated
            hematological neoplasm (SM-AHN) (n=138); and 2 months in mast cell
            leukemia (MCL) (n=4). Median follow-up was 20.7 months.<sup>10</sup>
          </li>
        </ul>
      </div>
    </div>

    <div className="row lg_mt-4 mt-4  medium-uncollapse">
      <div className="columns">
        <div
          className="hero-breaker hero-breaker--lesions"
          style={{ "--bg-image": `url(${aboutSmDykBg})` }}
        >
          <div className="row align-middle align-justify">
            <div className="columns small-12 medium-expand">
              <p className="color--purple text--watch-quinn h1">
                Did you know? Symptoms of SM
                <br className="show-for-large" /> in adults can be nonspecific,{" "}
                <br className="show-for-large" />
                manifesting{" "}
                <span className="powder">
                  {" "}
                  across multiple
                  <br className="show-for-large" /> organ systems
                </span>
                <sup>1-3</sup>
              </p>
              <br />
              <p className="color--purple h2 lead bodyP lesionBodyP">
                Patients living with SM can present with a range of
                unpredictable and potentially severe symptoms that can be
                triggered by aspects of everyday life.<sup>1-3,12</sup>
              </p>
              <br />
              <p className="color--purple h2 lead bodyP lesionBodyP">
                Symptoms of SM may present as mastocytosis in the skin, severe
                allergic reactions including anaphylaxis, diarrhea, or other
                mast cell activation syndrome symptoms.<sup>1,13</sup>
              </p>
            </div>
            <div
              className="columns hero-breaker--person hero-breaker--person--lesions small-12 large-5 hero-breaker--person--lesions--aboutsm"
              style={{ "--bg-image": `url(${aboutSmDykLesions})` }}
              title="Systemic mastocytosis skin lesions"
            >
              <span className="visually-hidden">
                Woman-lifting-shirt-to-show-skin-lesions
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-7 mt-7 lg_mb-7 mb-7 align-center medium-align-left">
      <div className="columns small-12 medium-expand text-right">
        <p className="h4 color--purple mobileBodyP">
          Recognizing SM symptoms is crucial
        </p>
        <Link
          className="btn btn--outline btn--icon uppercase mt-1"
          to="/recognizing-sm/symptoms/"
        >
          Explore SM Symptoms{" "}
          <FontAwesomeIcon className="" icon={faAngleRight} />
        </Link>
      </div>
    </div>

    <FooterCrown type="home" />

    <ReferenceBlock>
      <ol className="color--purple">
        <li>
          Pardanani A. <em>Am J Hematol</em>. 2023;98(7):1097-1116.
        </li>
        <li>
          Gülen T et al. <em>J Intern Med</em>. 2016;279(3):211-228.
        </li>
        <li>
          Theoharides TC et al. <em>N Engl J Med</em>. 2015;373(2):163-172.
        </li>
        <li>
          Kristensen T et al. <em>Am J Hematol</em>. 2014;89(5):493-498.
        </li>
        <li>
          Garcia-Montero AC et al. <em>Blood</em>. 2006;108(7):2366-2372.
        </li>
        <li>
          Ungerstedt J et al. <em>Cancers</em>. 2022;14(16):3942.
        </li>
        <li>
          Sperr WR et al. <em>Lancet Haematol</em>. 2019;6(12):e638-e649.
        </li>
        <li>
          Cohen SS et al. <em>Br J Haematol</em>. 2014;166(4):521-528.
        </li>
        <li>
          Mesa RA et al. <em>Cancer</em>. 2022;128(20):3691-3699.
        </li>
        <li>
          Lim KH et al. <em>Blood</em>. 2009;113(23):5727-5736.
        </li>
        <li>
          Trizuljak J. <em>Allergy</em>. 2020;75(8):1927-1938.
        </li>

        <li>
          Jennings SV et al. <em>Immunol Allergy Clin North Am</em>.
          2018;38(3):505-525.
        </li>
        <li>
          Berezowska S et al. <em>Mod Pathol</em>. 2014;27(1):19-29.
        </li>
      </ol>
    </ReferenceBlock>
  </Layout>
)

export default AboutSMPage
